import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
    searchBoxContainer: {
        width: "auto",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        alignItems: "center",
        fontSize: "1rem",
        fontWeight: 400,
        backgroundColor: theme.searchBoxContainer?.close?.backgroundColor ??
            theme.colorCard?.background ??
            "#FFFFFF",
    },
    searchBoxContainerOpen: {
        maxWidth: "400px",
        width: "100%",
        height: 48,
        borderColor: theme.searchBoxContainer?.open?.borderColor ?? "#FFFFFF",
        borderStyle: theme.searchBoxContainer?.open?.borderStyle ?? "solid",
        borderWidth: theme.searchBoxContainer?.open?.borderWidth ?? "0px",
        borderRadius: theme.searchBoxContainer?.open?.borderRadius ?? "0px",
        backgroundColor: theme.searchBoxContainer?.open?.backgroundColor ?? "#FFFFFF",
        paddingLeft: "8px",
        paddingRight: 8,
        [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            paddingLeft: "10px",
            width: "100%",
        },
    },
    searchIcon: {
        width: "24px",
        height: "24px",
        marginRight: 8,
        "&:hover": {
            cursor: "pointer",
        },
    },
    placeholderPrompt: {
        color: theme?.searchBoxContainer?.close?.placeholderColor ?? "#323537",
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.searchBoxContainer?.fontWeight ?? 400,
    },
    input: {
        textAlign: "start",
        width: "100%",
        border: "none",
        outline: "none",
        fontSize: "1rem",
        color: theme.searchBoxContainer?.open?.inputColor ?? "#323537",
        backgroundColor: theme.searchBoxContainer?.open?.backgroundColor,
        fontWeight: theme.searchBoxContainer?.fontWeight ?? 400,
        "&::-ms-clear": {
            display: "none",
            width: 0,
            height: 0,
        },
        fontFamily: theme.typography.fontFamily,
        "&::-webkit-search-cancel-button": {
            "-webkit-appearance": "none",
        },
        "&::placeholder": {
            color: theme.searchBoxContainer?.open?.placeholderColor ?? "#323537",
            fontWeight: theme.searchBoxContainer?.fontWeight ?? 400,
            opacity: 1,
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "4px",
        },
    },
}));
